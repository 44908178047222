import indicatorDict from "../components/indicatordata.json";
import ScreenerService from "../pages/api/screener_service";

const ConditionRow = ({ loopData }, props) => {
  const comparatorParamsSettings = (params, comparator, comparatorData) => {
    return ScreenerService.comparatorParamsSettings(
      params,
      comparator,
      comparatorData
    );
  };

  function IndparamsSettings(params) {
    return ScreenerService.checkParamSetting(params);
  }

  function dictKeyExists(key, array) {
    return ScreenerService.dictKeyExists(key, array);
  }

  function paramsSettings(params) {
    var paramSettingData = [];

    if ("source" in params) {
      paramSettingData = [
        ...paramSettingData,
        indicatorDict[params["source"]]["name"],
      ];
    }
    if ("std-deviation" in params) {
      paramSettingData = [...paramSettingData, params["std-deviation"]];
    }
    if ("k" in params) {
      paramSettingData = [...paramSettingData, params["k"]];
    }
    if ("d" in params) {
      paramSettingData = [...paramSettingData, params["d"]];
    }
    if ("smooth" in params) {
      paramSettingData = [...paramSettingData, params["smooth"]];
    }
    if ("fast_length" in params) {
      paramSettingData = [...paramSettingData, params["fast_length"]];
    }
    if ("slow_length" in params) {
      paramSettingData = [...paramSettingData, params["slow_length"]];
    }
    if ("signal_length" in params) {
      paramSettingData = [...paramSettingData, params["signal_length"]];
    }
    if ("length" in params) {
      paramSettingData = [...paramSettingData, params["length"]];
    }
    if ("offset" in params) {
      var offsetdata = params["offset"] + " candles ago";
      paramSettingData = [...paramSettingData, offsetdata];
    }
    if ("number" in params) {
      paramSettingData = [...paramSettingData, params["number"]];
    }
    var data = "(" + paramSettingData.join(", ") + ")";
    return data;
  }

  function getRange(params:any){
    if(params.hasOwnProperty("range")){
      return params["range"];
    }else{
      return params["offset"];
    }
  }

  return (
    <>
      {loopData["lhs"]["name"] == "" ? (
        "Select Indicator"
      ) : (
        <>
          {loopData["lhs"].hasOwnProperty("max") && (
            <>
              <label className="max-saved-indicator">Max</label> &nbsp;
            </>
          )}
          {loopData["lhs"].hasOwnProperty("min") && (
            <>
              <label className="min-saved-indicator">Min</label> &nbsp;
            </>
          )}
          <label className="saved-indicators">{loopData["lhs"]["name"]}</label>
        </>
      )}
      {dictKeyExists("params", loopData["lhs"]) ? (
        <>
        <label style={{ color: "black" }}>
          {"("}
          {IndparamsSettings(loopData["lhs"]["params"]) == "" ? (
            ""
          ) : (
            <span className="">
              {IndparamsSettings(loopData["lhs"]["params"])}
            </span>
          )}
          {")"}
        </label>
        {(loopData["lhs"].hasOwnProperty("max") || loopData["lhs"].hasOwnProperty("min")) && (
          <> <label className={`${loopData["lhs"].hasOwnProperty("max")?"max-saved-indicator":"min-saved-indicator"}`}>Between {`(${loopData["lhs"]["params"]["offset"]},${getRange(loopData["lhs"]["params"])})`}</label> &nbsp;</>
        )}
        </>
      ) : (
        ""
      )}
      &nbsp;
      {loopData["lhs"].hasOwnProperty("lhs") && (
        <>
          <label
            style={{
              paddingRight: "3px",
              paddingLeft: "3px",
              backgroundColor: "#88305f",
              borderRadius: "6px",
              color: "white",
              fontWeight: "bold",
              paddingBottom: "1px",
            }}
          >
            Minus
          </label>
          &nbsp;
          {loopData["lhs"]["lhs"].hasOwnProperty("max") && (
            <>
              <label className="max-saved-indicator">Max</label> &nbsp;
            </>
          )}
          {loopData["lhs"]["lhs"].hasOwnProperty("min") && (
            <>
              <label className="min-saved-indicator">Min</label> &nbsp;
            </>
          )}
          <label className="saved-indicators">
            {loopData["lhs"]["lhs"]["name"]}
          </label>
          {loopData["lhs"]["lhs"].hasOwnProperty("params") && (
            <>
            <label style={{ color: "black" }}>
              {"("}
              {IndparamsSettings(loopData["lhs"]["lhs"]["params"]) == "" ? (
                ""
              ) : (
                <span className="">
                  {IndparamsSettings(loopData["lhs"]["lhs"]["params"])}
                </span>
              )}
              {")"}
            </label>
            {(loopData["lhs"]["lhs"].hasOwnProperty("max") || loopData["lhs"]["lhs"].hasOwnProperty("min")) && (
          <> <label className={`${loopData["lhs"]["lhs"].hasOwnProperty("max")?"max-saved-indicator":"min-saved-indicator"}`}>Between {`(${loopData["lhs"]["lhs"]["params"]["offset"]},${getRange(loopData["lhs"]["lhs"]["params"])})`}</label> &nbsp;</>
        )}
            </>
          )}
        </>
      )}
      &nbsp;
      {loopData["comparator"].hasOwnProperty("name") ? (
        <label className="saved-comparator">
          {comparatorParamsSettings(
            loopData["comparator"]["params"],
            loopData["comparator"]["name"],
            loopData["comparator"]
          )}
        </label>
      ) : (
        "Select Comparator"
      )}
      &nbsp;
      {dictKeyExists("name", loopData["rhs"]) ? (
        <>
          {loopData["rhs"]["term"] == "number" ? null : (
            <>
            <>
              {loopData["rhs"].hasOwnProperty("max") && (
                <>
                  &nbsp; <label className="max-saved-indicator">Max</label> &nbsp;
                </>
              )}
              {loopData["rhs"].hasOwnProperty("min") && (
                <>
                  &nbsp; <label className="min-saved-indicator">Min</label> &nbsp;
                </>
              )}
            </>
              <label className="saved-indicators">
                {loopData["rhs"]["name"] === "Last X Candle"? `Last ${loopData["rhs"]["params"]["offset"]} Candle`: loopData["rhs"]["name"] }
                {/* {loopData["rhs"]["name"]} */}
              </label>
              
            </>
          )}
        </>
      ) : (
        "Select Indicator"
      )}
      
      {dictKeyExists("params", loopData["rhs"]) ? (
        <>
        <label style={{ color: "black" }}>
          {IndparamsSettings(loopData["rhs"]["params"]) == "" ? (
            ""
          ) : (
            <>
              {loopData["rhs"]["term"] == "number" ? (
                <span className="saved-indicators-number">
                  {" "}
                  {IndparamsSettings(loopData["rhs"]["params"])}
                </span>
              ) : (
                <>
                 {loopData["rhs"]["name"] === "Last X Candle"? null: <>{"("}
                  <span className="">
                    {IndparamsSettings(loopData["rhs"]["params"])}
                  </span>
                  {")"}</> }
                  
                </>
              )}
            </>
          )}
        </label>
        
        {(loopData["rhs"].hasOwnProperty("max") || loopData["rhs"].hasOwnProperty("min")) && (
          <> <label className={`${loopData["rhs"].hasOwnProperty("max")?"max-saved-indicator":"min-saved-indicator"}`}>Between {`(${loopData["rhs"]["params"]["offset"]},${getRange(loopData["rhs"]["params"])})`}</label> &nbsp;</>
        )}

        </>
      ) : (
        ""
      )}
      &nbsp;
      {loopData["rhs"].hasOwnProperty("rhs") && (
        <>
          <label
            style={{
              paddingRight: "3px",
              paddingLeft: "3px",
              backgroundColor: "#88305f",
              borderRadius: "6px",
              color: "white",
              fontWeight: "bold",
              paddingBottom: "1px",
            }}
          >
            Minus
          </label>
          &nbsp;
          <>
              {loopData["rhs"]["rhs"].hasOwnProperty("max") && (
                <>
                  &nbsp; <label className="max-saved-indicator">Max</label> &nbsp;
                </>
              )}
              {loopData["rhs"]["rhs"].hasOwnProperty("min") && (
                <>
                  &nbsp; <label className="min-saved-indicator">Min</label> &nbsp;
                </>
              )}
            </>
          <label className="saved-indicators">
          {loopData["rhs"]["rhs"]["name"] === "Last X Candle"? `Last ${loopData["rhs"]["rhs"]["params"]["offset"]} Candle`: loopData["rhs"]["rhs"]["name"] }
                
          {/* {loopData["rhs"]["rhs"]["name"]} */}
          </label>
          {loopData["rhs"]["rhs"].hasOwnProperty("params") && loopData["rhs"]["rhs"]["name"] !== "Last X Candle" && (
            <>
            <label style={{ color: "black" }}>
              {"("}
              {IndparamsSettings(loopData["rhs"]["rhs"]["params"]) == "" ? (
                ""
              ) : (
                <span className="">
                  {IndparamsSettings(loopData["rhs"]["rhs"]["params"])}
                </span>
              )}
              {")"}
            </label>
            {(loopData["rhs"]["rhs"].hasOwnProperty("max") || loopData["rhs"]["rhs"].hasOwnProperty("min")) && (
              <> <label className={`${loopData["rhs"]["rhs"].hasOwnProperty("max")?"max-saved-indicator":"min-saved-indicator"}`}>Between {`(${loopData["rhs"]["rhs"]["params"]["offset"]},${getRange(loopData["rhs"]["rhs"]["params"])})`}</label> &nbsp;</>
            )}
            </>
          )}
        </>
      )}
      &nbsp;
    </>
  );
};

export default ConditionRow;
